import React from "react";
import "../styles/main.scss";
import '../fonts/Ethnocentric/ethnocentric_rg.ttf';
import '../fonts/Ethnocentric/ethnocentric_rg_it.ttf';

import Header from "../components/header/header";
//import Footer from "../components/footer/footer";
import EventStream from "../components/eventStream/eventStream";
import Sponsors from "../components/sponsors/sponsors";
// import {Banners} from "../components/banners/banners";
import { Helmet } from "react-helmet"
import moment from 'moment';

const VIDEO_SWITCH_THRESHOLD = "2020-09-16 17:22";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "IIA | Italian Insurtech Association",
      description: "La nostra è l’Associazione di riferimento, italiana, per gli attori della filiera assicurativa che intendono, innovare, sviluppare la nuova offerta di protezione ed affrontare le nuove sfide lanciate dal consumatore digitale, al fine di migliorare la sua soddisfazione e la penetrazione dell'offerta assicurativa sul mercato Italiano.",
      image: "/og_image.png",

      lastTimeChecked: moment().valueOf(),
      video: moment() < moment(VIDEO_SWITCH_THRESHOLD) ? "https://player.vimeo.com/video/453744981" : "https://player.vimeo.com/video/453744981"
    }
  }

  componentDidMount() {

    /* CHAT */

    (function(d, w, c) {
      w.ChatraID = 'NYPZMuwaf7MdvEL2P';
      var s = d.createElement('script');
      w[c] = w[c] || function() {
        (w[c].q = w[c].q || []).push(arguments);
      };
      s.async = true;
      s.src = 'https://call.chatra.io/chatra.js';
      if (d.head) d.head.appendChild(s);
    })(document, window, 'Chatra');

    //this.trickToChangeVimeoLink();

  }

  trickToChangeVimeoLink() {
    setInterval(() => {
      const { lastTimeChecked } = this.state;
      if(moment(lastTimeChecked) < moment(VIDEO_SWITCH_THRESHOLD) && moment() > moment(VIDEO_SWITCH_THRESHOLD))  {
        window.location.reload();
      }
      this.setState({ lastTimeChecked: moment().valueOf() })
    }, 2000);
  }

  render() {

    const { title, description, image } = this.state;

    return (
      <>

        <Helmet>
          <meta charSet="utf-8" />
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:type" content={"website"} />
          <meta property="og:url" content="https://summit.insurtechitaly.com/" />
          <meta property="og:image" content={image} />
          <title>{title}</title>
          <link rel="canonical" href="https://summit.insurtechitaly.com/" />

        </Helmet>

        <div className={"main_wrapper"}>
          <Header />
          <EventStream />
          <Sponsors />
          {/*<Banners />*/}
        </div>

        {/*<Footer />*/}

      </>
    );

  }
}


export default Home;