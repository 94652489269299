import React from "react"
import "./header.scss";

export default function Header({ children }) {
  return (
    <header>

      <h1>Italian Insurtech Summit</h1>
      <h2>Ora o mai più</h2>

      <div className={"event_info"}>
        <p><strong>20 e 21 Settembre 2021</strong></p>
        <p className={"sub-title"}>Investimenti, Competenze, Sperimentazione, Visione Globale.</p>
      </div>

    </header>
  );
}