import React from 'react';
import "./eventStream.scss";
//import { Button } from '@material-ui/core';

export default function EventStream () {

	return (

		<div className={"eventStream"}>
			<iframe title="stream" src="https://vimeo.com/event/1285566/embed" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen="" style={{margin: "1em 0", width: "100%", height: "600px"}} />
			{/*<img src={"/banners/preview.jpg"} style={{width: "100%", marginTop: "1em"}} />*/}
			{/*<Button href={"https://www.insurtechitaly.com/"} className={"cta"} variant={"contained"} size={"large"}>Torna al sito</Button>*/}
		</div>
	);

}