import React from 'react';
import "./sponsors.scss";

const sponsorListPatronage = [
	"/sponsors/comunemilano.png",
	"/sponsors/Acb.jpeg",
	"/sponsors/Agid.jpeg",
	"/sponsors/Assolombarda.jpeg",
	"/sponsors/Anra.jpeg",
	"/sponsors/Aiba.jpeg",
	"/sponsors/DIA.png",
]

const sponsorListPlatinum = [

	"/sponsors/Reale.png",
	"/sponsors/icg.jpeg",
	"/sponsors/accenture.png",

];

const sponsorListGold = [

	"/sponsors/INTESA2.png",
	"/sponsors/Helbiz.png",
	"/sponsors/Slimpay.png",
	"/sponsors/BEstf.png",
	"/sponsors/crif.png",
	"/sponsors/Afiesca.png",
	"/sponsors/LEITHA_Unipol.png",
	"/sponsors/Zerlos.png",
	"/sponsors/NET.png",
	"/sponsors/CConsSPA.png",
	"/sponsors/Sara_Assicura.png",

];

const sponsorListSilver = [

	"/sponsors/HELVETIA.png",
	"/sponsors/Deloitte.png",
	"/sponsors/VittoriaHUB.png",
	"/sponsors/Fido.png",
	"/sponsors/Expert.png",
	"/sponsors/DAT.png",
	"/sponsors/EY.png",
	"/sponsors/chubb.jpeg",
	"/sponsors/Dacadoo.png",
	"/sponsors/YOLO.jpeg",
	"/sponsors/Microdata.png",
	"/sponsors/Vaance.png",
	"/sponsors/conflux.jpeg",
	"/sponsors/Sogesa.png",
	"/sponsors/Prometeia.png",
	"/sponsors/pooley.jpeg",
	"/sponsors/wide.jpeg",
	"/sponsors/DPA.png",
	"/sponsors/G2I.png",
	"/sponsors/rga.jpeg",
	"/sponsors/AppQuality.png",
	"/sponsors/Grifotech.png",
	"/sponsors/Propensione.png",
	"/sponsors/metlife.jpeg",
	"/sponsors/neosurance.jpeg",
	"/sponsors/Allianz.png",
	"/sponsors/Aon.png",
	"/sponsors/iarena.jpeg",
	"/sponsors/vitesicure.jpeg",
	"/sponsors/cyber_angels.png",
	"/sponsors/tiassisto24.jpeg",
	"/sponsors/Assiteca.png",
	"/sponsors/toyota_fs.png",

];

const sponsorListMedia = [

	"/sponsors/insurzine.png",
	"/sponsors/Forbes.png",
	"/sponsors/LeFonti.png",
	"/sponsors/D360.png",
	"/sponsors/Athesis.png",
	"/sponsors/DealFlower.png",
	"/sponsors/TP.png",

];

const agendaList = [
	{
		session: "Apertura",
		items: [
			{
				speaker1: "Simone Ranucci Brandimarte",
				role1: "Fondatore e Presidente Yolo Group e Italian Insurtech Association",
				title: "Benvenuti all'Italian Insurtech Summit 2021!",
			},
		]
	},
	{
		session: "Insurtech & Innovazione: la visione Istituzionale",
		items: [
			{
				speaker1: "Roberta Cocco",
				role1: "Assessora a Trasformazione digitale e Servizi civici - Comune di Milano",
				title: "Il digitale, al cuore del Comune di Milano",
			},
			{
				speaker1: "Mattia Mor",
				role1: "Parlamentare della Repubblica Italiana",
				title: "Messaggio di buon inizio lavori",
			},
			{
				speaker1: "Pietro Franchini",
				role1: "Vice Capo Servizio-Servizio Studi e Gestione Dati IVASS",
				title: "La visione dell'IVASS",
			},
			{
				speaker1: "Simone Ranucci Brandimarte",
				role1: "Fondatore e Presidente Yolo Group e Italian Insurtech Association",
				title: " 2021: lo stato dell’Insurtech in Italia.",
			},
			{
				speaker1: "Flavio Sestilli",
				role1: "Vicepresidente - AIBA",
				title: "Il digitale in Italia: la visione dell'AIBA",
			},
			{
				speaker1: "Davide Vacher",
				role1: "Consiglio Direttivo e Giunta Esecutiva ACB",
				title: "Il Patrocinio di ACB all'Italian Insurtech Summit 2021",
			},
			{
				speaker1: "Claudio Demozzi",
				role1: "Presidente Nazionale SNA",
				title: "Ottimizzare l'utilizzo della tecnologia per accrescere le potenzialità distributive degli agenti",
			},
			{
				speaker1: "Matteo Carbone",
				role1: "Non-Executive Director, Co-Founder, Insurtech Thought Leader, Keynote speaker and writer on insurance innovation",
				title: "Insurtech: una visione globale",
			},
			{
				speaker1: "Gianluca De Cobelli",
				role1: "Membro del consiglio generale di Assolombarda",
				title: "Il Patrocinio di Assolombarda all'Italian Insurtech Summit 2021",
			},
			{
				speaker1: "Erik Oliviero Somaschini",
				role1: "Imprenditore e Business Angel",
				title: "Insurtech e prospettive future per le agenzie assicurative",
			},
			{
				speaker1: "Gabriele Ferrieri",
				role1: "Presidente di ANGI - Associazione Nazionale Giovani Innovatori",
				title: "Innovazione e future generazioni: dal PNRR agli investimenti sul digitale per il rilancio dell'italia",
			},



			{
				speaker1: "Davide Vacher",
				role1: "Consiglio Direttivo Fondazione Mantovani Castorina",
				title: "Breve presentazione di FMC e ringraziamento per il contributo ricevuto",
			},
			{
				speaker1: "Clara Woods",
				role1: "Artist",
				title: "L'arte è innovazione!",
			},
		]
	},
	{
		session: "Sessione 1 - Digitalizzazione e Sviluppo: la sfida dell’industria assicurativa",
		items: [
			{
				speaker1: "Giuseppe Dosi",
				role1: "Head of Insurance Market, CRIF",
				title: "Insurtech, Innovazione e Sviluppo: una sfida per tutta l'Industria",
			},
			{
				speaker1: "Andrea Lorenzoni",
				role1: "Insurance Lead Italy Central Europe & Greece, Accenture",
				title: "Leaders Wanted",
			},
			{
				speaker1: "Alessandro Santoliquido",
				role1: "Insurance Leader",
				title: "Punti chiave da considerare nell’Insurtech",
			},
			{
				speaker1: "Andrea Veltri",
				role1: "Deputy CEO",
				title: "BNP Paribas Cardif Insurt-tech o Tech-Insurance?",
			},
			{
				speaker1: "Alessandro Viola",
				role1: "Head of Corporate Sales Nexi",
				title: "Nexi 4 Insurance",
			},
			{
				speaker1: "Igor Rossini",
				role1: "Managing Director & Equity Partner VAANCE",
				title: "Enable your Insurance Business – fattori chiave per il successo",
			},
			{
				speaker1: "Marco Trombetti",
				role1: "Co-founder & CEO Translated",
				title: "AI per supportare invece che sostituire",
			},
			{
				speaker1: "Francesco Catalfo",
				role1: "Sogesa Srl",
				title: "Let's chat!",
			},
			{
				speaker1: "Paolo Fiorentino",
				role1: "Sales Manager Dacadoo Ag",
				title: "Dacadoo DHEP e RISK Engine",
			},
			{
				speaker1: "Marco Concordati",
				role1: "Associate Partner, Insurance, EY",
				title: "L'evoluzione necessaria del mercato auto",
			},
			{
				speaker1: "Rosalia di Maggio",
				role1: "Direttore Mercato FSI Microsoft Italia",
				title: "Insurance Innovazione, Cambiamento ed Evoluzione del settore Insurance",
			},
		]
	},
	{
		session: "Sessione 2 - Tecnologia ed Innovazione: una prospettiva globale dagli industry leaders",
		items: [
			{
				speaker1: "Yuri Poletto, Rita Yates, Robin Merttens, Christian Gnam, Kobi Bendelak e Amit Tiwari",
				role1: "GLOBAL INSURANCE ALLIANCE PANEL",
				title: "The state of global Insurtech - a chat with other GITA members",
			},
			{
				speaker1: "Matt Connolly",
				role1: "CEO & Founder Sønr",
				title: "The growing importance of Open Insurance",
			},
			{
				speaker1: "Roger Peverelli",
				role1: "Co-Founder Digital Insurance Agenda",
				title: "The perfect storm",
			},
			{
				speaker1: "Alena Tsishchanka",
				role1: "Insurance Leader EMEA",
				speaker2: "Stefano Maggioni",
				role2: "Presales Manager Advanced Analytics & AI - SAS",
				title: "The dark side of AI: processi analitici etici per un futuro più sicuro",
			},
			{
				speaker1: "Steven Mendel",
				role1: "Ceo Bought by Many",
				title: "A view from Bought by Many for the Italian Insurtech Community",
			},
			{
				speaker1: "Michele Corbò",
				role1: "Senior Account Executive Salesforce",
				title: "Compagnie, Intermediari & Fintech: crescere, collaborare e innovare con il cliente al centro",
			},
			{
				speaker1: "Stefano Bison",
				role1: "Group Head of Business Development & Innovation Generali",
				title: "Start Up Insurtech: un asset fondamentale per l'innovazione del settore assicurativo",
			},
			{
				speaker1: "Fulvio Locanto",
				role1: "Future",
				title: "Shaping the future of insurance",
			},
			{
				speaker1: "Simeone Scaramozzino",
				role1: "Immersive Artist & Producer",
				title: "The art of connected well-being",
			},
			{
				speaker1: "Antonio Milici",
				role1: "Head of Insurance Previnet s.p.a.",
				title: "L’innovazione utile: il sesto senso digitale",
			},
			{
				speaker1: "Marco Paganotto",
				role1: "Marketing & Sales Insurance & Service Manager - FCA Bank S.p.A.",
				title: "La distribuzione digitale delle polizze assicurative nel mercato finanziario per l'automotive",
			},
			{
				speaker1: "Micheal Dritsas",
				role1: "Ceo Vlot",
				title: "Enabling people to make financially responsible decision",
			},
			{
				speaker1: "Orio Navarra",
				role1: "Digital IT Director - Aon Italia",
				title: "Il valore delle relazioni umane nel processo di innovazione dell'interazione con il cliente",
			},
			{
				speaker1: "Adrien Lebegue",
				role1: "Head of Strategy - ZhongAn",
				title: "ZhongAn, a pioneer in embedded insurance",
			},
			{
				speaker1: "James York",
				role1: "Co-Founder - Insurtech UK",
				title: "If you're diffrent - be diffrent",
			},
		]
	},
	{
		session: "Sessione 3 - Crescere grazie agli ecosistemi",
		items: [
			{
				speaker1: "Paola Corna Pellegrini",
				role1: "Ceo Allianz Partners",
				title: "Ecosistemi: competenze che convergono per un'innovazione esponenziale",
			},
			/*{
				speaker1: "Gerardo Di Francesco",
				role1: "Co-fondatore e Segretario Generale Italian Insurtech Association",
				title: "L’ecosistema insurtech italiano tra tecnologie e modelli di business: il caso Wide Group",
			},*/
			{
				speaker1: "Carlo Dettori",
				role1: "Editore insurzine e co-founder iarena Insurtech",
				title: "Passare da analogico a digitale",
			},
			{
				speaker1: "Giovanni Cannavò",
				role1: "Presidente CdA Medexpert",
				title: "Il digitale in Medicina Legale: il Progetto del Bareme Digitale Medexpert",
			},
			{
				speaker1: "Alessandro Paoli",
				role1: "Business Development Director Zurich LiveWell Services and Solutions Ltd",
				title: "L'innovazione e la digitalizzazione dell'assicurazione sulla Salute",
			},
			{
				speaker1: "Alessandro Longoni",
				role1: "Head Fintech Distrect",
				title: "Fintech, Insurtech & Open Innovation",
			},
			{
				speaker1: "Gabriele Benedetto",
				role1: "Chief Executive Officer Telepass Spa",
				title: "Ovunque la vità ci porti, la mobiità è sempre più un fattore in grado di influenzare la qualità!",
			},
			{
				speaker1: "Gianfranco Giannella",
				role1: "Hyris Executive Chairman",
				title: "The rise of Digital Therapeutics. Impacts for insurers",
			},
			{
				speaker1: "Bibop Gresta",
				role1: "Co-Founder and CEO Hyperloop Italia",
				title: "Come Hyperloop rivoluzionerà il mondo dei trasporti anche in Italia",
			},
			{
				speaker1: "Tina Martino",
				role1: "Head of Strategic Marketing and Intelligence Octo Telematics",
				title: "Octo Telematics: un ecosistema di successo",
			},
			{
				speaker1: "Mario La Femina",
				role1: "Ceo Toyota Insurance Management Italy",
				title: "Investire nell'innovazione digitale nel settore assicurativo italiano",
			},
			{
				speaker1: "Natalia Antongiovanni",
				role1: "Business Development Officer, Insurance Consulting Group SpA",
				title: "Ecosistemi cross industry, parametriche e oracoli: assicurare al giorno d'oggi",
			},
			{
				speaker1: "Andrea Andreta",
				role1: "Ceo Arag Italy and Member of Group Executive Committee",
				title: "La Tutela Legale: da copertura accessoria ad ecosistema per la tutela dei diritti",
			},
			{
				speaker1: "Santiago Sanchez",
				role1: "Head of Digital Europe Chubb",
				title: "Chubb Studio: Insurance for the new possible",
			},
			{
				speaker1: "Matteo Pisani",
				role1: "Venture Associate & Corporate Innovation Consultant, Plug and Play Ventures Associate",
				title: "Are digital ecosystems the next big thing in Insurance?",
			},
			{
				speaker1: "Francesco Zaini",
				role1: "Open Finance Director G2 STARTUPS",
				speaker2: "Simone Ranucci Brandimarte",
				role2: "Fondatore e Presidente Yolo Group e Italian Insurtech Association",
				title: "Un playground per l'open insurance",
			},
		]
	},
	{
		session: "Sessione 4 - Sustainability & Diversity: serve l’ottica di lungo periodo",
		items: [

			{
				speaker1: "Barbara Falcomer",
				role1: "Managing Director Valore D",
				title: "Le persone sono l'innovazione",
			},
			{
				speaker1: "Pamela Negosanti",
				role1: "Head of Sector Strategy FSI expert.aii",
				title: "Diversità Sostenibile: Cosa fare ora?",
			},
			{
				speaker1: "Carlo Noseda",
				role1: "Presidente IAB",
				title: "Noi siamo il digitale sostenibile",
			},
			{
				speaker1: "Alessia Truini",
				role1: "Head of Product Development Intesa San Paolo Assicura",
				title: "Insurtech: Insurance as a service",
			},
			{
				speaker1: "Andrea Spada",
				role1: "ESG Specialist Ecomate Eu",
				title: "Taxonomy Help companies transition towards sustainability",
			},
			{
				speaker1: "Laura Balla",
				role1: "Marketing and Communication Director MetLife Italy",
				title: "Sostenibilità come driver per l'innovazione",
			},
			{
				speaker1: "Paolo Dalmagioni",
				role1: "Responsabile Divisione Costruzioni Sace BT",
				title: "Sace: un caso di successo per la sostenibilità",
			},
			{
				speaker1: "Gianfranco Baldinotti",
				role1: "Presidente Vittoria Hub",
				title: "Investments: the ugly truth",
			},
			{
				speaker1: "Arturo Artom",
				role1: "Fondatore Cenacolo Artom",
				title: "Revers mentoring",
			},
			{
				speaker1: "Imma Battaglia",
				role1: "Manager Gruppo DigiTouch",
				title: "Green Deal è uguale inclusion",
			},
		]
	},
	{
		session: "Sessione 5 - Investire in innovazione: come rimuovere i freni",
		items: [
			{
				speaker1: "Andrea Birolo",
				role1: "Head of Corporate Venturing Reale Group",
				title: "Cambiamento e trasformazione: spingere le corporate a creare innovazione con le startup",
			},
			{
				speaker1: "Andrea Battista",
				role1: "Ceo Net Insurance",
				title: "Sfide e opportunità dell'investimento Insurtech",
			},
			{
				speaker1: "Simone Ranucci Brandimarte",
				role1: "Fondatore e Presidente Yolo Group e Italian Insurtech Association",
				title: "Dobbiamo aumentare gli investimenti!",
			},
			{
				speaker1: "Marco Giorgino",
				role1: "Direttore Scientifico Osservatorio Fintech & Insurtech and Full Professor of Financial Markets and Institutions al Politecnico di Milano",
				title: "Insurtech Investment Index: update 2021",
			},
			{
				speaker1: "Gianluca Dettori",
				role1: "Presidente Primo Ventures",
				title: "2021: l'anno del Hightech?",
			},
			{
				speaker1: "Simone Panizza",
				role1: "International markets coordinator C CONSULTING",
				title: "Innovazione e riassicurazione",
			},
			{
				speaker1: "Rodolfo Pambianco",
				role1: "Director, FIG & Insurtech Europe Barclays",
				title: "Investimenti in Insurtech: una prospettiva globale",
			},
			{
				speaker1: "Stefano Battista",
				role1: "EY Italy Financial Services Market Leader",
				title: "Trasformazione e Collaborazione: il binomio per il futuro dell’industria",
			},
			{
				speaker1: "Adriano De Matteis",
				role1: "Managing Director per l’Italia e Chief Marketing Officer per la regione EMEA di RGA International Reinsurance Company",
				title: "Solcare mari in tempesta",
			},
			{
				speaker1: "Franco Baronio",
				role1: "Partner Bain & Company",
				title: "Investimenti in Insurtech: a che punto siamo?",
			},
			{
				speaker1: "Marcello Vena",
				role1: "Partner Simbiosity",
				title: "Corporate Innovation su Larga Scala. L’era dei POC è finita",
			},
			{
				speaker1: "Alberto Minali",
				role1: "Amministratore Delegato REVO Spac",
				title: "Investimenti in tecnologia nei piani di sviluppo di REVO",
			},
			{
				speaker1: "Massimiliano Mascoli",
				role1: "Head of Insurtech, Business execution & International business - Sara Assicurazioni S.p.A.",
				title: "Reinventing insurance model: SARA Assicurazioni a case study",
			},
			{
				speaker1: "Gianluca Melani",
				role1: "Managing Director Wide Group",
				title: "L'importanza del co investimento",
			},
			{
				speaker1: "Florian Graillot",
				role1: "Investor Astorya",
				title: "Insurtech Europe: overview & trends in H1 2021"
			},
		]
	},
	{
		session: "Sessione 6 - Competenze, professionalità e contesti normativi: nuove opportunità",
		items: [
			{
				speaker1: "Andrea Polizzi",
				role1: "Co-Founder and board member D'Argenio Polizzi e Associati Studio Legale",
				title: "La necessità di best practices in un ecosistema insurtech",
			},
			{
				speaker1: "Nik Blanarik",
				role1: "Co-founder FintechJobs.Today",
				title: "Innovazione nel fintech HR italiano, insights del nostro primo anno",
			},
			{
				speaker1: "Andrea Soldo",
				role1: "Senior Consultant Innotech Hub",
				title: "The European House-Ambrosetti i3 - Innovation In Insurance",
			},
			{
				speaker1: "Alberto Rossi",
				role1: "Ceo Conversion E3",
				title: "Il Marketing è cambiamento",
			},



			{
				speaker1: "Antonio Scognamillo",
				role1: "Chief Commercial Officer Amissima Assicurazioni",
				title: "Le nuove frontiere della formazione",
			},
			{
				speaker1: "Alessandro Serra",
				role1: "Head of Sales and Marketing DAT",
				speaker2: "Maurizio Monaco",
				role2: "Data Science Business Translator PROMETEIA",
				title: "FastTrackAI®: la miglior combinazione di dati, conoscenze specialistiche e tecnologie avanzate per le nuove sfide del claim management",
			},
			{
				speaker1: "Mattia Salerno",
				role1: "Avvocato Studio Pirola Pennuto Zei & Associati",
				title: "Legaltech: come impatteranno sul mercato legale tecnologie, Intelligenza artificiale, digitalizzazione?",
			},
			{
				speaker1: "Annamaria Iannibelli",
				role1: "ex studentessa in Ing Gestionale presso Politecnico di Torino",
				speaker2: "Carla La Tona",
				role2: "Technology strategy consulting Analyst",
				title: "Stima della diffusione di policy assicurative digitali",
			},
			{
				speaker1: "Giovanni Grava",
				role1: "Amministratore Delegato Tutela Legale S.p.A.",
				title: "Nuove normative e sfida dell'innovazione",
			},
			{
				speaker1: "Giacomo Farneti",
				role1: "Responsabile Sanitario e ricercatore",
				title: "Il sistema sanitario italiano negli ultimi due anni: la visione del professionista in prima linea",
			},
			{
				speaker1: "Filippo Celoria",
				role1: "Avvocato e co-founder di Lawing",
				title: "Firma elettronica + video conferenza: una digitalizzazione umana delle customer relations",
			},
			{
				speaker1: "Andrea Rangone",
				role1: "Presidente Digital 360 & Giovanni Iozzia - Direttore EconomyUp",
				speaker2: "Giovanni Iozzia",
				role2: "Direttore EconomyUp",
				title: "Il digitale in Italia",
			},
			{
				speaker1: "Chiara Burberi",
				role1: "Co-founder Redocc",
				title: "Competenze in STEAM, base della sostenibilità",
			},
		]
	},
	{
		session: "Sessione 7 - Innovazione come Mindset",
		items: [
			{
				speaker1: "Renzo Giovanni Avesani",
				role1: "Chief Executive Officer Leithà e Chief Innovation Officer UnipolSai",
				title: "It’s all software, stupid!",
			},
			{
				speaker1: "Sergio Spaccavento",
				role1: "Chief Creative Officer Conversion E3",
				title: "L'umorismo in comunicazione ai tempi del Covid",
			},

			{
				speaker1: "Ignazio Rocco di Torrepadula",
				role1: "Founder & CEO Credimi",
				title: "Small Business Tech: Credimi in 3 numbers",
			},
			{
				speaker1: "Giorgio Viviani",
				role1: "CEO GrifoTech",
				title: "InnovAzione: Rivoluzione attraverso il cambiamento del paradigma",
			},
			{
				speaker1: "Edoardo Monaco",
				role1: "CEO e founder Axieme",
				speaker2: "Massimo Michaud",
				role2: "Expert in Insurtech and Corporate Governance, Business Advisor, formerly Ceo Allianz Italia and Axa Assicurazioni",
				title: "Insurtech 2030, il futuro è già qui",
			},
			{
				speaker1: "Gabriella Scapicchio",
				role1: "CEO - Le Village by Crédit Agricole Milano",
				title: "Le Village by Credit Agricole Milano",
			},

			{
				speaker1: "Giampaolo Rossi",
				role1: "Founder & CEO - Fabbrica di Lampadine e Oltre La Media Group",
				title: "Innovare: istruzioni per l'uso",
			},
			{
				speaker1: "Giovanni Saladino",
				role1: "Head of Sales Zelros Italia",
				title: "AI for Augmented Insurers",
			},
			{
				speaker1: "Carlo Alberto Minasi",
				role1: "Chief Innovation Officer & Growth Drivers Leader, Financial Services EY",
				title: "Market surprise: il potere delle anomalie per creare vantaggio competitivo",
			},
			{
				speaker1: "Gianni Danieli",
				role1: "Sales & Business Development Director",
				title: "New Normal: Tra Digital e Phygital",
			},
			{
				speaker1: "Francesco Cavina",
				role1: "Ceo MyBiros",
				title: "IA, documenti e automazione: una soluzione innovativa per il data entry",
			},
			{
				speaker1: "Francesco Magro",
				role1: "Ceo & Founder Winelivery",
				title: "Importanza del Brand in una strategia Blue Ocean",
			},
			{
				speaker1: "Nicoletta Besio",
				role1: "Sales Director Twitch Italy",
				title: "Pensi che Twitch sia solo per i gamers? Dallo sport, alla musica e ai talk show. Twitch è per tutti!",
			},

			{
				speaker1: "Filippo Gaggini",
				role1: "Managing Partner Progressio SGR",
				title: "L'innovazione secondo Progressio SGR",
			},
			{
				speaker1: "Stefano Moro",
				role1: "Managing Director General Expert",
				title: "Il mondo è cambiato. Le compagnie sapranno cogliere le opportunità?",
			},
			{
				speaker1: "Matteo Gelati",
				role1: "COO - ENDU",
				title: "Sportech e Insurtech verso il mercato consumer",
			}
		]
	},
	{
		session: "Sessione 8 - Upgrading financial services",
		items: [
			{
				hour:"08.30-09.30 ",
				speaker1: "Fabio Carniol",
				role1: "Managing Director and General Manager Helvetia Italia and Helvetia Vita",
			},
			{
				hour:"08.30-09.30 ",
				speaker1: "Andrea Galbiati",
				role1: "Responsabile Sviluppo Assicurazioni Banca IFIS",
				title: "L’innovazione nelle PMI, nuovi rischi e nuovi bisogni",
			},
			{
				hour:"08.30-09.30 ",
				speaker1: "Matteo Rizzi",
				role1: "Co-Founder FTS Group - Venture Partner, Bamboo Capital Partners, Author",
				title: "The Rise of the Fintech Hybrids",
			},
			{
				hour:"08.30-09.30 ",
				speaker1: "Alessandro Braga",
				role1: "Corporate Transformation Director - Talent Garden",
				title: "L'Innovazione come creatività tecnologica",
			},
			{
				hour:"08.30-09.30 ",
				speaker1: "Luca Lodi",
				role1: "Head of R&D - FIDA, Finanza Dati Analisi",
				title: "F4I / FIDA4INSURANCE - abilitatore di innovazione",
			},
			{
				hour:"08.30-09.30 ",
				speaker1: "Francesco Sommariva",
				role1: "Head of Sales TIK TOK",
				title: "TIK TOK una nuova frontiera dei servizi finanziari",
			},
			{
				hour:"08.30-09.30 ",
				speaker1: "Paolo Sironi",
				role1: "Global Research Leader Banking and Financial Markets - IBM, Institute for Business Value",
				title: "The reinvention imperative!",
			},
			{
				hour:"08.30-09.30 ",
				speaker1: "Fabrizio Villani",
				role1: "Cofounder Fintastico.com",
				title: "Open Banking e Open Finance",
			},
		]
	},
	{
		session: "Sessione 9 - Global Outlook: Insurtech Giants",
		items: [
			{
				hour:"09.30-10.30 ",
				speaker1: "Romano Sacchi",
				role1: "Partner, Operating Model Transformation, Insurance Leader - Deloitte Consulting",
				title: "Innovazione come efficienza nei processi di \"value exchange\"",
			},
			{
				hour:"09.30-10.30 ",
				speaker1: "Stefano D'Ellena",
				role1: "Head of Insurance Practice Everis Italia",
				title: "InsurTech OutLook – Sintesi dei risultati e trend 2021",
			},
			{
				hour:"09.30-10.30 ",
				speaker1: "Stefano Bellandi",
				role1: "EMEA Insurance Core Transformation Leader & Global Core Transformation Impact Center Leader PwC",
				title: "Come diventare un aggregatore",
			},
			{
				hour:"09.30-10.30 ",
				speaker1: "Piero Gancia",
				role1: "Senior Partner McKinsey & Company",
				title: "Insurtech: la fabbrica degli unicorn",
			},
		]
	},
	{
		session: "Sessione 10 - Open Insurance: nuove opportunità di distribuzione",
		items: [
			{
				hour:"09.30-10.30 ",
				speaker1: "Gianluca De Cobelli",
				role1: "Amministratore Delegato Yolo",
				title: "Il valore delle partnership a supporto di una strategia insurtech di successo",
			},
			{
				hour:"10.30-11.30 ",
				speaker1: "Yuri Poletto",
				role1: "Insurance Open innovation consultant",
				title: "What is Open Insurance? And why now?",
			},
			{
				hour:"10.30-11.30 ",
				speaker1: "Federico Soncini Sessa",
				role1: "Ceo & co-founder Mia-Platform",
				title: "Innovation through cooperation in the Insurance industry",
			},
			{
				hour:"10.30-11.30 ",
				speaker1: "Michele Romagnoli",
				role1: "CEO & Co-founder Tiassisto24",
				title: "Driver services platform",
			},
			{
				hour:"10.30-11.30 ",
				speaker1: "Francesco Dal Piaz",
				role1: "Country Manager Italy and Spain Wakam",
				title: "Come l'assicurazione sarà integrata nei prodotti e nei servizi",
			},
			{
				hour:"10.30-11.30 ",
				speaker1: "Florida Bushi",
				role1: "Digital Marketing Manager SlimPay",
				title: "Reinventing everyday insurance to reimagine the customer experience",
			},
		]
	},
	{
		session: "Sessione 11 - A tech vision: l’assicuratore Bionico",
		items: [
			{
				hour:"10.30-11.30 ",
				speaker1: "Gerardo Di Francesco",
				role1: "Co-fondatore e Segretario Generale Italian Insurtech Association",
				title: "L’ecosistema insurtech italiano tra tecnologie e modelli di business: il caso Wide Group",
			},
			{
				hour:"11.30-12.30 ",
				speaker1: "Tomaso Mansutti",
				role1: "Head of International Partnerships",
				title: "Tecnologia e Assicurazione: la visione di Wefox",
			},

			{
				hour:"11.30-12.30 ",
				speaker1: "Daniele Cordioli",
				role1: "Head of Solutions Consulting EMEA, expert.ai",
				title: "Human-in-the-loop",
			},
			{
				hour:"11.30-12.30 ",
				speaker1: "Paolo Mardegan",
				role1: "Chief Commercial Officer Fido",
				title: "Digital Score by Fido: come conoscere i propri prospect",
			},
			{
				hour:"11.30-12.30 ",
				speaker1: "Paolo Rossetti",
				role1: "Strategy & Management Consulting and Associate Member Italian Insurtech Association",
				title: "\"Digi Umanization’\" – Le esigenze di innovazione degli agenti assicurativi",
			},
			{
				hour:"11.30-12.30 ",
				speaker1: "Andrea Toponi",
				role1: "CEO & Co-Founder - Cyberangels",
				title: "Cyber insurance platform: portare valore all'ecosistema assicurativo.",
			},
			{
				hour:"11.30-12.30 ",
				speaker1: "Luca Concone",
				role1: "Fondatore e CEO - Familcare",
				title: "Teconologia, cura e innovazione",
			},
			{
				hour:"11.30-12.30 ",
				speaker1: "Laura Cavagnini",
				role1: "Marketing & Innovation Manager ADD Value",
				title: "Innovare in un'azienda tech",
			},
		]
	},
	{
		session: "Sessione 12 - The rise of digital customer: una nuova offerta da costruire",
		items: [
			{
				hour:"11.30-12.30 ",
				speaker1: "Luigi Greco",
				role1: "Founder & Ceo Conflux",
				title: "Evidence-based design for digital",
			},
			{
				hour:"12.30-13.30 ",
				speaker1: "Ashley Benigno",
				role1: "Group Director Fjord Milan",
				title: "Trends 2021",
			},
			{
				hour:"12.30-13.30 ",
				speaker1: "Lorenzo Fanetti",
				role1: "Head of Sales & Digital Quality Consultant App Quality",
				title: "Chi trova i tuoi bug? Tu o i tuoi clienti?",
			},
			{
				hour:"12.30-13.30 ",
				speaker1: "Fabio Sellitti",
				role1: "Sales Representative Namirial",
				title: "Group Namirial: empowering trusted digital transformation",
			},
			{
				hour:"12.30-13.30 ",
				speaker1: "Alessandro Turra",
				role1: "Chief Marketing Officer Founder Vite Sicure",
				title: "La nuova generazione dell’assicurazione vita-protezione attraverso la trasformazione dell’esperienza d’acquisto",
			},
			{
				hour:"12.30-13.30 ",
				speaker1: "Rossella Vignoletti",
				role1: "Chief Marketing Officer Net Insurance",
				title: "Il nuovo consumatore digitale",
			},
			{
				hour:"13.30-14.30 ",
				speaker1: "Giuseppe di Riso",
				role1: "Director Division Globals Swiss RE",
				speaker2: "Alessandro Paoli",
				role2: "Business Development Director - Zurich LiveWell Services and Solutions Ltdv",
				title: "La rivoluzione del sistema assicurativo attraverso la tecnologia e la customer centricity: il caso assicurazione salute",
			},
			{
				hour:"13.30-14.30 ",
				speaker1: "Andrea Isola",
				role1: "General Manager Italy & Southeast Europe N26",
				title: "N26's new insurance offer",
			},
			{
				hour:"13.30-14.30 ",
				speaker1: "Luca Loforese",
				role1: "Responsabile Marketing & Comunicazione Afi Esca Italia",
				title: "#IoScelgoAfiEsca per la digitalizzazione l’industria assicurativa",
			},
			{
				hour:"13.30-14.30 ",
				speaker1: "Roberta Pazzini",
				role1: "Head of Marketing & Communication Yolo",
				title: "La digital (human) experience nell’era post-Covid",
			},
			{
				hour:"13.30-14.30 ",
				speaker1: "Juan Vicente Carda Guinot",
				role1: "Sales Executive Slimpay Italia",
				title: "Massimizza il valore del nuovo cliente digitale",
			},
			{
				hour:"13.30-14.30 ",
				speaker1: "Claudio Lorenzon",
				role1: "Digital Marketing Specialist Propensione",
				title: "La digital transformation nella previdenza integrativa: la mission di Propensione",
			},
			{
				hour:"13.30-14.30 ",
				speaker1: "Sylvain Quernè",
				role1: "Consigliere ASSITECA e General Manager di 6sicuro",
				title: "Il Broker interprete dei bisogni dei digital customer",
			},
			{
				hour:"14.30-15.00 ",
				speaker1: "Augusto Coppola",
				role1: "CEO - Cloud Accelerator",
				title: "Una questione di testa",
			},
		]
	},
	{
		session: "Sessione 13 – Insurtech Awards (in presenza su invito, in streaming per tutti)",
		items: [
			{
				hour: "15.00",
				speaker1: "APERTURA Italian Insurtech Awards",
			},
			{
				hour: "15.00-15.20",
				speaker1: "KEYNOTE",
				role1: "Simone Ranucci Brandimarte",
			},
			/*{
				hour: "14.50-15.00",
				speaker1: "MODERATORE",
				role1: "Gerardo Di Francesco",
			},
			{
				hour: "14.30-15.00",
				speaker1: "Premiazione",
				role1: "Il Migliore Prodotto Digital Retail, Il Miglior Prodotto Digital Corporate, I web services più efficaci, La migliore iniziativa pro Ecosistema Insurtech dell'anno",
			},*/
			{
				hour: "15.20-15.30",
				speaker1: "KEYNOTE",
				role1: "MATTEO CARBONE",
			},
			{
				hour: "15.30-15.40",
				speaker1: "Inizio Cerimonia di premiazione",
				role1: "con Gerardo di Francesco",
			},
			{
				hour: "17.00-17.20",
				role1: "Pausa Caffè",
			},
			/*{
				hour: "16.30-17.00",
				speaker1: "Premiazione",
				role1: "Miglior progetto di internazionalizzazione, Progetto di blockchain più di successo, Progetto AI più di successo, Progetto di Open Insurance più di successo",
			},*/
			{
				hour: "17.20-17.40",
				speaker1: "KEYNOTE",
				role1: "MATTEO RIZZI",
			},
			{
				hour: "17.40-19.15",
				speaker1: "Prosegue Cerimonia di premiazione",
				role1: "con Gerardo di Francesco",
			},
			{
				hour: "19.15",
				speaker1: "Il Premio della Critica",
				role1: "di Insurzine",
			},
			{
				hour: "19.30",
				speaker1: "Chiusura",
				role1: "Awards & Ringraziamenti",
			},
			{
				hour: "19.45-21.30",
				role1: "Networking & Happy Hour",
			},
		]
	},
];

export default function Sponsors() {

	return (
		<div className={"sponsors"}>

			<div className={"category patrocinio"}>
				<h3>
					<small>Evento patrocinato da</small>
				</h3>
				{sponsorListPatronage.map((s, i) => (
					<Sponsor type={"patrocinio"} key={i} image={s} />
				))}
			</div>

			<div className={"category agenda"}>
				<h3>
					Agenda
				</h3>
				{agendaList.map((element, index) =>
					<div key={index}>
						<h4>{element.session}</h4>
						<ul>
							{element.items.map((item, index) =>
								<AgendaItem key={index} item={item}/>
							)}
						</ul>
					</div>
				)}

			</div>

			<div className={"category platinum"}>
				<h3>Sponsor Platinum</h3>
				{sponsorListPlatinum.map((s, i) => (
					<Sponsor type={"platinum"} key={i} image={s} />
				))}
			</div>

			<div className={"category gold"}>
				<h3>Sponsor Gold</h3>
				{sponsorListGold.map((s, i) => (
					<Sponsor key={i} image={s} />
				))}
			</div>

			<div className={"category silver"}>
				<h3>Sponsor Silver</h3>
				{sponsorListSilver.map((s, i) => (
					<Sponsor type={"silver"} key={i} image={s} />
				))}
			</div>

			<div className={"category media"}>
				<h3>Media Partner</h3>
				{sponsorListMedia.map((s, i) => (
					<Sponsor type={"media"} key={i} image={s} />
				))}
				{/*<img src={"/sponsors/insurzine.png"} alt="partner media" style={{height: "150px"}} />*/}
			</div>

		</div>
	);

}

const Sponsor = ( { image, type = "gold" } ) => {
	return (
		<img src={image} className={type} alt={"sponsor"} />
	)
}

const AgendaItem = (item) => {
	return (
			<li>
				<p>
					{item.item.hour ? <><span className={"role"}>{item.item.hour}</span>&nbsp;</> : null}
					{item.item.speaker1 ? <span className={"speaker"}>{item.item.speaker1}</span> : null}
					{item.item.role1 ? <>&nbsp;&ndash;&nbsp;<span className={"role"}>{item.item.role1}</span></> : null}
					{item.item.speaker2 ?
						<>&nbsp;&amp;&nbsp;<span className={"speaker"}>{item.item.speaker2}</span>
							{item.item.role2 ? <>&nbsp;&ndash;&nbsp;<span className={"role"}>{item.item.role2}</span></> : null}
						</>
						: null}
					{item.item.title ? <>&nbsp;|&nbsp;<span className={"title"}>{item.item.title}</span></> : null}
				</p>
			</li>
	)
}